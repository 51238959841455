import React from "react";
import tableClasses from "Styles/mainCss.module.css";
import { IoClose } from "react-icons/io5";
import Moment from "react-moment";
// import User from "../../Assets/user.svg";
function AddCategory(props) {
    const transactionDetails = props.transactionDetails;
    console.log(transactionDetails);
    const CancelHandler = (e) => {
        e.stopPropagation();
        props.setmodal(false);
    };

    const openhandler = (e) => {
        e.stopPropagation();
    };

    return (
        <div className={tableClasses.modal} onClick={CancelHandler}>
            <div
                className={`${tableClasses["modal-content"]} ${tableClasses["modal-transaction-details"]} `}
                onClick={openhandler}>
                <div className={tableClasses["modal-content-header"]}>
                    <div>
                        <h3>Transaction Details</h3>
                    </div>
                    <button
                        className={tableClasses["close-btn"]}
                        onClick={CancelHandler}>
                        <IoClose size={22} color={"red"} />
                    </button>
                </div>
                <div className={tableClasses["add-items"]}>
                    {/* <img
                        src={
                            transactionDetails.profilePic !== "NA"
                                ? transactionDetails.profilePic
                                : User
                        }
                        alt="profilepic"
                    /> */}
                    <div
                        className={`${tableClasses["transaciton-detail-items"]} ${tableClasses["greyBg"]}`}>
                        <h3>User ID</h3>
                        <p>:</p>
                        <p>{transactionDetails?.userId}</p>
                    </div>
                    <div
                        className={`${tableClasses["transaciton-detail-items"]} ${tableClasses["whitebg"]}`}>
                        <h3>Name</h3>
                        <p>:</p>
                        <p>
                            {transactionDetails.transactionType == 2
                                ? transactionDetails.name
                                : transactionDetails?.firstName}
                        </p>
                    </div>
                    <div
                        className={`${tableClasses["transaciton-detail-items"]} ${tableClasses["greyBg"]}`}>
                        <h3>
                            {transactionDetails.transactionType == 2
                                ? "Payout mobile number"
                                : "Mobile Number"}
                        </h3>
                        <p>:</p>
                        <p>
                            {transactionDetails.transactionType == 2
                                ? transactionDetails.payoutmobileNumber
                                : transactionDetails.mobileNumber}
                        </p>
                    </div>

                    <div
                        className={`${tableClasses["transaciton-detail-items"]} ${tableClasses["whitebg"]}`}>
                        <h3>transactionId</h3>
                        <p>:</p>
                        <p>{transactionDetails?.wallet_transactionId}</p>
                    </div>

                    <div
                        className={`${tableClasses["transaciton-detail-items"]} ${tableClasses["greyBg"]}`}>
                        <h3>Gateway</h3>
                        <p>:</p>
                        <p>{transactionDetails?.paymentGateway}</p>
                    </div>
                    <div
                        className={`${tableClasses["transaciton-detail-items"]} ${tableClasses["whitebg"]}`}>
                        <h3>ipAddress</h3>
                        <p>:</p>
                        <p>{transactionDetails?.ipAddress}</p>
                    </div>
                    <div
                        className={`${tableClasses["transaciton-detail-items"]} ${tableClasses["greyBg"]}`}>
                        <h3>razorypay Id</h3>
                        <p>:</p>
                        <p>{transactionDetails?.razorpay_TransactionId}</p>
                    </div>
                    <div
                        className={`${tableClasses["transaciton-detail-items"]} ${tableClasses["whitebg"]}`}>
                        <h3>payout contactId</h3>
                        <p>:</p>
                        <p>{transactionDetails?.payout_contactId}</p>
                    </div>
                    <div
                        className={`${tableClasses["transaciton-detail-items"]} ${tableClasses["greyBg"]}`}>
                        <h3>UTR</h3>
                        <p>:</p>
                        <p>{transactionDetails?.utr}</p>
                    </div>
                    <div
                        className={`${tableClasses["transaciton-detail-items"]} ${tableClasses["whitebg"]}`}>
                        <h3>transaction Date</h3>
                        <p>:</p>
                        <p>
                            <Moment
                                format="MMMM Do YYYY hh:mm a"
                                subtract={{ hours: 5, minutes: 30 }}>
                                {
                                    new Date(
                                        transactionDetails?.transactionDateTime
                                    )
                                }
                            </Moment>
                        </p>
                    </div>
                    <div
                        className={`${tableClasses["transaciton-detail-items"]} ${tableClasses["greyBg"]}`}>
                        <h3>Sub Total</h3>
                        <p>:</p>
                        <p>₹{transactionDetails?.subtotalAmount}</p>
                    </div>
                    <div
                        className={`${tableClasses["transaciton-detail-items"]} ${tableClasses["whitebg"]}`}>
                        <h3>Charges</h3>
                        <p>:</p>
                        <p>₹{transactionDetails?.charges}</p>
                    </div>
                    <div
                        className={`${tableClasses["transaciton-detail-items"]} ${tableClasses["greyBg"]}`}>
                        <h3>Total</h3>
                        <p>:</p>
                        <p>₹{transactionDetails?.amount}</p>
                    </div>
                    <div
                        className={`${tableClasses["transaciton-detail-items"]} ${tableClasses["whitebg"]}`}>
                        <h3>Transaction Status</h3>
                        <p>:</p>
                        <p style={{ margin: "0" }}>
                            {transactionDetails?.transactionStatus === 1 &&
                                "Processed"}
                            {transactionDetails?.transactionStatus === 2 &&
                                "Pending"}
                            {transactionDetails?.transactionStatus === 3 &&
                                "Payout Failed"}
                            {transactionDetails?.transactionStatus === 4 &&
                                "Payout Reversed"}
                        </p>
                    </div>
                    <div
                        className={`${tableClasses["transaciton-detail-items"]} ${tableClasses["greyBg"]}`}>
                        <h3>Closing Balance</h3>
                        <p>:</p>

                        <p style={{ margin: "0" }}>
                            ₹ {transactionDetails?.after_balance}
                        </p>
                    </div>
                    <div
                        className={`${tableClasses["transaciton-detail-items"]} ${tableClasses["whitebg"]}`}>
                        <h3>Invoice</h3>
                        <p>:</p>
                        {transactionDetails.InvoiceFile !== "NA" ? (
                            <a
                                href={transactionDetails.InvoiceFile}
                                target={"_blank"}
                                rel="noreferrer">
                                Download
                            </a>
                        ) : (
                            <p>NA</p>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddCategory;
