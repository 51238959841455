import React from "react";
import cssClasses from "Styles/Style.module.css";
import Sidenav from "Components/sideNav/sideNav";
function Layout({ isactive, children }) {
    return (
        <div className={cssClasses["wrapper"]}>
            <div className={cssClasses["left-div"]}>
                <Sidenav isActive={isactive} />
            </div>
            <div className={cssClasses["right-div"]}>{children}</div>
        </div>
    );
}

export default Layout;
