const httpcall = (url, method, body, token) => {
    return fetch(`${process.env.REACT_APP_BASE_URL}/${url}`, {
        method: method,
        body: body,
        headers: {
            "Content-type": "application/json; charset=UTF-8",
            ...token,
        },
    }).then((response) => response.json());
};
const login = (method, body) => {
    return httpcall("admin/login", method, body);
};
const Users = (method, body, token, offset) => {
    return httpcall(`admin/users/all?offset=${offset}`, method, body, token);
};
const VerifiedUsers = (method, body, token, offset) => {
    return httpcall(
        `admin/users/all?offset=${offset}&isKYCverified=1`,
        method,
        body,
        token
    );
};
const ApprovalUsers = (method, body, token, offset) => {
    return httpcall(
        `admin/users/all?offset=${offset}&isKYCverified=1&type=approval`,
        method,
        body,
        token
    );
};
const kycPendingUsers = (method, body, token, offset) => {
    return httpcall(
        `admin/users/all?offset=${offset}&isKYCverified=0`,
        method,
        body,
        token
    );
};
const kycsemiverifiedUsers = (method, body, token, offset) => {
    return httpcall(
        `admin/users/all?offset=${offset}&isKYCverified=0&type=0`,
        method,
        body,
        token
    );
};
const pendingTransactionsApprove = (method, body, token) => {
    return httpcall(`admin/add-razorpay-payment`, method, body, token);
};
const pendingTransactions = (method, body, token) => {
    return httpcall(`admin/pending-razorpay-payments`, method, body, token);
};

const Transactions = (method, body, token) => {
    return httpcall(`admin/wallet-transactions`, method, body, token);
};
const TransactionsById = (method, body, token, id) => {
    return httpcall(`admin/wallet-transactions/${id}`, method, body, token);
};
const TransactionsDownload = (method, body, token) => {
    return httpcall(`admin/wallet-transactions/download`, method, body, token);
};
const userTransactions = (method, body, token, id, offset) => {
    return httpcall(
        `admin/${id}/wallet-transactions?Offset=${offset}
    `,
        method,
        body,
        token
    );
};
const walletRecharges = (method, body, token) => {
    return httpcall(`admin/wallet-recharges?offset=0`, method, body, token);
};
const kycUpdate = (method, body, token, userid) => {
    return httpcall(`admin/${userid}/update-userdetails`, method, body, token);
};
const getSettings = (method, body, token) => {
    return httpcall(`admin/settings`, method, body, token);
};
const getadminUsers = (method, body, token, offset) => {
    return httpcall(
        `admin/admin-users/all?Offset=${offset}`,
        method,
        body,
        token
    );
};
const addAdmin = (method, body, token) => {
    return httpcall(`admin/add`, method, body, token);
};
const updateAdmin = (method, body, token, id) => {
    return httpcall(`admin/${id}/update-details`, method, body, token);
};
const getAdminDetailsById = (method, body, token, id) => {
    return httpcall(`admin/${id}/details`, method, body, token);
};
const DeleteAdmin = (method, body, token, id) => {
    return httpcall(`admin/${id}/delete`, method, body, token);
};
const getReferral = (method, body, token, offset) => {
    return httpcall(
        `admin/referralcode/all?Offset=${offset}`,
        method,
        body,
        token
    );
};
const addReferral = (method, body, token) => {
    return httpcall(`admin/referralcode/add`, method, body, token);
};
const DeleteReferral = (method, body, token, id) => {
    return httpcall(
        `admin/referralcode/${id}/delete
        `,
        method,
        body,
        token
    );
};
const updateReferral = (method, body, token, id) => {
    return httpcall(`admin/referralcode/${id}/update`, method, body, token);
};
const walletSummary = (method, body, token, offset) => {
    return httpcall(
        `admin/users/wallet-summary?Offset=${offset}`,
        method,
        body,
        token
    );
};
const UserDetailsById = (method, body, token, id) => {
    return httpcall(`admin/users?userId=${id}`, method, body, token);
};
const SearchUserBynameOrMobile = (method, body, token) => {
    return httpcall(`admin/search-users`, method, body, token);
};
const SearchReferralUserBynameOrMobile = (method, body, token) => {
    return httpcall(`admin/search-referralcodes`, method, body, token);
};
const addBlockedUser = (method, body, token) => {
    return httpcall(`admin/blockedAccounts/add`, method, body, token);
};
const deleteBlockedUser = (method, body, token, id) => {
    return httpcall(`admin/blockedAccounts/${id}/delete`, method, body, token);
};
const getBlockedUsers = (method, body, token, offset) => {
    return httpcall(
        `admin/blockedAccounts/all?Offset=${offset}
        `,
        method,
        body,
        token
    );
};
const getPayoutContacts = (method, body, token, id) => {
    return httpcall(
        `admin/${id}/payout-contacts
        `,
        method,
        body,
        token
    );
};
const getDashboard = (method, body, token, id) => {
    return httpcall(
        `admin/dashboard
        `,
        method,
        body,
        token
    );
};
const getDashboardFilter = (method, body, token, fromDate, toDate) => {
    return httpcall(
        `admin/dashboard?fromDate=${fromDate}&toDate=${toDate}
        `,
        method,
        body,
        token
    );
};
const downloadverifiedCsav = (method, body, token, offset) => {
    return httpcall(
        `admin/users/all/csv?offset=${offset}&isKYCverified=1`,
        method,
        body,
        token
    );
};
const downloadsemiverifiedCav = (method, body, token, offset) => {
    return httpcall(
        `admin/users/all/csv?isKYCverified=1&type=1`,
        method,
        body,
        token
    );
};
const downloadpendingcsv = (method, body, token, offset) => {
    return httpcall(
        `admin/users/all/csv?offset=0&isKYCverified=0`,
        method,
        body,
        token
    );
};
const approvalcsv = (method, body, token) => {
    return httpcall(
        `admin/users/all/csv?isKYCverified=1&type=approval`,
        method,
        body,
        token
    );
};
const referralusers = (method, body, token, id, offset) => {
    return httpcall(
        `admin/${id}/referred-users/mobileNumber?offset=${offset}`,
        method,
        body,
        token
    );
};
const referralCommission = (method, body, token, offset) => {
    return httpcall(
        `admin/referral-commission-transactions?Offset=${offset}`,
        method,
        body,
        token
    );
};
const t1Transactions = (method, body, token, offset, status) => {
    return httpcall(
        `admin/payout-transactions-tone?Offset=${offset}&Status=${status}`,
        method,
        body,
        token
    );
};
const UpdateTransaction = (method, body, token, id) => {
    return httpcall(
        `admin/${id}/admin-approval`,
        method,
        body,
        token
    );
};
const PayoutVerify = (method, body, token, offset) => {
    return httpcall(
        `admin/payout-contacts/approval-pending?Offset=${offset}`,
        method,
        body,
        token
    );
};
const PayoutApprove = (method, body, token, payoutcontactId) => {
    return httpcall(
        `admin/${payoutcontactId}/payout-contacts/IsAdminApproved`,
        method,
        body,
        token
    );
};
const rejected = (method, body, token, offset) => {
    return httpcall(
        `admin/payout-contacts/rejected?Offset=${offset}`,
        method,
        body,
        token
    );
};
const resetKYC = (method, body, token, userid) => {
    return httpcall(
        `admin/${userid}/reset-userKYCdetails`,
        method,
        body,
        token
    );
};
const downloadCsvPendingT1 = (method, body, token, userid) => {
    return httpcall(
        `admin/payout-transactions-tone/csv`,
        method,
        body,
        token
    );
};
const verifyBankDetails = (method, body, token, userid) => {
    return httpcall(
        `admin/${userid}/verify-bankdetails`,
        method,
        body,
        token
    );
};

export const Services = {
    t1Transactions,
    verifyBankDetails,
    referralCommission,
    referralusers,
    TransactionsDownload,
    TransactionsById,
    downloadCsvPendingT1,
    SearchUserBynameOrMobile,
    login,
    Users,
    kycPendingUsers,
    resetKYC,
    VerifiedUsers,
    Transactions,
    walletRecharges,
    kycUpdate,
    getSettings,
    getadminUsers,
    addAdmin,
    DeleteAdmin,
    updateAdmin,
    getReferral,
    addReferral,
    DeleteReferral,
    updateReferral,
    walletSummary,
    UserDetailsById,
    userTransactions,
    addBlockedUser,
    getBlockedUsers,
    deleteBlockedUser,
    getAdminDetailsById,
    SearchReferralUserBynameOrMobile,
    getPayoutContacts,
    getDashboard,
    getDashboardFilter,
    downloadverifiedCsav,
    pendingTransactions,
    pendingTransactionsApprove,
    UpdateTransaction,
    kycsemiverifiedUsers,
    downloadsemiverifiedCav,
    downloadpendingcsv,
    approvalcsv,
    ApprovalUsers,
    rejected,
    PayoutVerify,
    PayoutApprove

};
