import React, { useEffect, useState } from "react";
import Layout from "Components/utilities/Layout";
import tableClasses from "Styles/mainCss.module.css";
import Table from "Components/utilities/table";
import Loader from "Components/utilities/loader";
import { Services } from "Services";
import { toast } from "react-toastify";
import { AiOutlineDelete } from "react-icons/ai";
import AddAdmin from "Components/BlockedAccounts/Adduser";
import Moment from "react-moment";
function User() {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    let firstTime;
    const [addForm, setAddForm] = useState(false);
    const [prevoffset, setPrevOffset] = useState();
    const [next, setNext] = useState(true);
    const [prev, setprev] = useState(true);
    const [offset, setoffset] = useState(0);
    const [editable, setEditable] = useState(false);
    const [max, setMax] = useState();
    const headerstyles = {
        color: "#1a1717",
        backgroundColor: "white",
        fontWeight: 600,
        fontSize: "0.9rem",
        width: "fit-content",
        padding: "0 0.3rem",
        textAlign: "center",
    };
    useEffect(() => {
        // eslint-disable-next-line
        firstTime = true;
    }, []);
    const formHandler = () => {
        setAddForm((prev) => !prev);
    };

    const getusers = (offset) => {
        Services.pendingTransactions("GET", null, token, offset)
            .then((Response) => {
                console.log(Response);
                // offset
                setLoading(false);
                if (Response.Status === 1) {
                    setData(Response.WalletTransactions);
                } else {
                    toast.error(`${Response.Message}`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    });
                }
            })
            .catch((err) => {
                alert("something went wrong please try later");
                console.log(err);
            });
    };
    useEffect(() => {
        getusers(0);

        // eslint-disable-next-line
    }, []);

    const paidHandler = (e) => {
        if (window.confirm("are you sure you want to Proceed ")) {
            let userId = e.currentTarget.getAttribute("userId");
            let razorpayOrderId =
                e.currentTarget.getAttribute("razorpayOrderId");
            let body = {
                razorpayOrderId: razorpayOrderId,
                userId: userId,
            };
            Services.pendingTransactionsApprove(
                "POST",
                JSON.stringify(body),
                token
            )
                .then((res) => {
                    if (res.Status === 1) {
                        getusers();
                        toast.success(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    } else if (res.Status === 0) {
                        toast.error(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        // if (res.Message === "Token expired") {
                        //     Navigate("/");
                        // }
                    }
                })
                .catch((err) => {
                    // alert(err);
                    alert("something went wrong please try again");
                    console.log(err);
                });
        }
    };

    const dateFormatter = (cell) => {
        const date = new Date(cell);
        return <Moment format="MMMM Do YYYY HH:mm a">{date}</Moment>;
    };
    const actionFormatter = (cell, cellContent) => {
        return (
            <button
                className="paid-button"
                razorpayOrderId={cellContent.razorpay_orderId}
                userId={cellContent.userId}
                onClick={paidHandler}>
                Mark as Paid
            </button>
        );
    };
    const columns = [
        {
            dataField: "userId",
            text: "User Id",
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },
        {
            dataField: "orderReceiptId",
            text: "Order ID",
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles, width: "20%" };
            },
        },
        {
            dataField: "razorpay_orderId",
            text: "Razorpay OrderId",
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },
        {
            dataField: "paymentGateway",
            text: "PGType",
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },
        {
            dataField: "orderAmount",
            text: "Amount",
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },
        {
            dataField: "createdAt",
            text: "Date",
            sort: false,
            formatter: dateFormatter,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },

        {
            dataField: "Action",
            text: "Action",
            sort: false,
            formatter: actionFormatter,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },
    ];
    console.log(addForm);
    return (
        <>
            <Layout isactive={"pending"}>
                {loading ? (
                    <Loader />
                ) : (
                    <div className={tableClasses["table"]}>
                        <h3 className={tableClasses["heading"]}>
                            Pending Transactions
                        </h3>

                        {/* <div className={tableClasses["search"]}>
                            <div>
                                <input
                                    type={"text"}
                                    placeholder="Search by name"
                                />
                                <BiSearchAlt2
                                    className={tableClasses["search-icon"]}
                                    size={25}
                                    color={"#2e3346"}
                                />
                            </div>
                            <button>
                                <BsDownload />
                                <p>Download</p>
                            </button>
                        </div> */}
                        <Table
                            data={data}
                            columns={columns}
                            getdata={getusers}
                            getoffset={offset}
                            max={max}
                            setNext={setNext}
                            next={next}
                            prev={prev}
                            setprev={setprev}
                            prevoffset={prevoffset}
                            setPrevOffset={setPrevOffset}
                        />
                    </div>
                )}
            </Layout>
        </>
    );
}

export default User;
