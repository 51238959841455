import React, { useEffect, useState } from "react";
import Layout from "Components/utilities/Layout";
import tableClasses from "Styles/mainCss.module.css";
import Loader from "Components/utilities/loader";
import { Services } from "Services";
import { toast } from "react-toastify";
// import Moment from "react-moment";
import { useNavigate, useParams } from "react-router-dom";
import Profile from "Assets/user.svg";
import EditUser from "Components/Verified/ediUser";
function User() {
    const Navigate = useNavigate();
    const { id } = useParams();
    const [modal, setModal] = useState(false);
    console.log(id);
    const [img, setimg] = useState("");
    const [data, setData] = useState([]);
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);
    const getusers = (offset) => {
        Services.UserDetailsById("GET", null, token, id)
            .then((Response) => {
                console.log(Response);
                // offset

                setLoading(false);
                if (Response.Status === 1) {
                    setData(Response.UserDetails);
                } else {
                    toast.error(`${Response.Message}`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    });
                }
            })
            .catch((err) => {
                alert("something went wrong please try later");
                console.log(err);
            });
    };
    useEffect(() => {
        getusers(0);
        // eslint-disable-next-line
    }, []);
    const userHandeler = (e) => {
        let type = e.currentTarget.getAttribute("btntype");
        let value = e.currentTarget.getAttribute("value");
        let body;
        if (type === "payout") {
            body = { isPayoutBlocked: value };
        } else if (type == "block") {
            body = {
                isBlocked: value,
            };
        } else if (type == "kyc") {
            body = {
                isKYCverified: 1,
            };
        }
        Services.kycUpdate("PUT", JSON.stringify(body), token, id)
            .then((Response) => {
                getusers(0);
                // offset
                if (Response.Status === 1) {
                    toast.success(`${Response.Message}`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    });
                } else {
                    toast.error(`${Response.Message}`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    });
                }
            })
            .catch((err) => {
                alert("something went wrong please try later");
                console.log(err);
            });
    };
    const verifyhandler = (e) => {
        let btn = e.currentTarget.getAttribute("btn-type");
        let body = { [btn]: 1 };
        Services.kycUpdate("PUT", JSON.stringify(body), token, id)
            .then((Response) => {
                getusers(0);
                // offset
                if (Response.Status === 1) {
                    toast.success(`${Response.Message}`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    });
                } else {
                    toast.error(`${Response.Message}`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    });
                }
            })
            .catch((err) => {
                alert("something went wrong please try later");
                console.log(err);
            });
    };
    const handleDownload = (imageUrl) => {
        const anchor = document.createElement("a");
        anchor.href = imageUrl;
        anchor.download = "image.jpg";
        anchor.click();
    };

    return (
        <>
            <Layout isactive={"Kyc semi"}>
                {loading ? (
                    <Loader />
                ) : (
                    <div className={tableClasses["table"]}>
                        <button
                            onClick={() => {
                                Navigate(-1);
                            }}
                            style={{
                                margin: "1rem 0",
                                justifySelf: "left",
                                backgroundColor: "red",
                            }}>
                            Back
                        </button>
                        <div className={tableClasses["block-btns"]}>
                            <h3 className={tableClasses["heading"]}>
                                Users Details
                            </h3>
                        </div>

                        <div className={tableClasses["user-details"]}>
                            {data.profilePic === "NA" ? (
                                <img src={Profile} alt="profilepic" />
                            ) : (
                                <img src={data.profilePic} alt="profilepic" />
                            )}

                            <div className={tableClasses["user-data"]}>
                                <h3>First name</h3>
                                <p>:</p>
                                <p>{data.firstName}</p>
                            </div>
                            <div className={tableClasses["user-data"]}>
                                <h3>Last name</h3>
                                <p>:</p>
                                <p>{data.lastName}</p>
                            </div>
                            <div className={tableClasses["user-data"]}>
                                <h3>Email</h3>
                                <p>:</p>
                                <p>{data.emailId}</p>
                            </div>
                            <div className={tableClasses["user-data"]}>
                                <h3>Address</h3>
                                <p>:</p>
                                <p>{data.address}</p>
                            </div>
                            <div className={tableClasses["user-data"]}>
                                <h3>Mobile number</h3>
                                <p>:</p>
                                <p>{data.mobileNumber}</p>
                            </div>
                            <div className={tableClasses["user-data"]}>
                                <h3>DOB</h3>
                                <p>:</p>
                                <p>{data.DOB}</p>
                            </div>

                            <div className={tableClasses["user-data"]}>
                                <h3>PAN</h3>
                                <p>:</p>
                                <p>{data.PAN}</p>
                            </div>
                            <div className={tableClasses["user-data"]}>
                                <h3>Aadhar</h3>
                                <p>:</p>
                                <p>{data.aadharNumber}</p>
                            </div>
                            <div className={tableClasses["user-data"]}>
                                <h3>KYC Holder Account No</h3>
                                <p>:</p>
                                <p>{data.KYCHolderAccountNo}</p>
                            </div>
                            <div className={tableClasses["user-data"]}>
                                <h3>KYC Holder Account IFSC</h3>
                                <p>:</p>
                                <p>{data.KYCHolderAccountIFSC}</p>
                            </div>
                            <div className={tableClasses["user-data"]}>
                                <h3>wallet Amount</h3>
                                <p>:</p>
                                <p>{data.walletAmount} rs</p>
                            </div>
                            <div className={tableClasses["user-data"]}>
                                <h3>payment PageId</h3>
                                <p>:</p>
                                <p>{data.paymentPageId}</p>
                            </div>
                            <div className={tableClasses["user-data"]}>
                                <h3>Payoutblocked</h3>
                                <p>:</p>
                                <p>
                                    {data.isPayoutBlocked === 1 ? "YES" : "NO"}
                                </p>
                            </div>
                            <div className={tableClasses["user-data"]}>
                                <h3>Referred by code</h3>
                                <p>:</p>
                                <p>{data.referredByCode}</p>
                            </div>
                            <div className={tableClasses["user-data"]}>
                                <h3>payout charge amount</h3>
                                <p>:</p>
                                <p>{data.payoutChargeAmount} rs</p>
                            </div>
                            <div className={tableClasses["user-data"]}>
                                <h3>wallet commission percentage</h3>
                                <p>:</p>
                                <p>{data.walletCommissionPercentage}%</p>
                            </div>
                            <div
                                className={`${tableClasses["user-data"]} ${tableClasses["user-data-img"]}`}>
                                <h3>PAN Image</h3>
                                <p>:</p>
                                <div>
                                    {data.PANImage != "NA" ? (
                                        <div>
                                            <img
                                                src={data.PANImage}
                                                alt="user"
                                            />
                                        </div>
                                    ) : (
                                        <p>NA</p>
                                    )}
                                </div>
                            </div>
                            <div
                                className={`${tableClasses["user-data"]} ${tableClasses["user-data-img"]}`}>
                                <h3>User Selfie</h3>
                                <p>:</p>
                                <div>
                                    {data.userSelfieURL != "NA" ? (
                                        <div>
                                            <img
                                                src={data.userSelfieURL}
                                                alt="user"
                                            />
                                        </div>
                                    ) : (
                                        <p>NA</p>
                                    )}
                                </div>
                            </div>
                            {/* <div className={tableClasses["user-data"]}>
                                <h3>wallet commission percentage</h3>
                                <p>:</p>
                                <p>{data.referredbyUserPhoneNumber}%</p>
                            </div> */}
                        </div>
                    </div>
                )}
            </Layout>
        </>
    );
}

export default User;
