import React, { useState, useEffect, useRef } from "react";
import Layout from "Components/utilities/Layout";
import DashboardClasses from "./dashboard.module.css";
import Loader from "Components/utilities/loader";
import { toast } from "react-toastify";
import { Services } from "Services";
import moment from "moment";
function Dashboard() {
    const fromRef = useRef();
    const toRef = useRef();
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const [loading, setLoading] = useState(true);
    const [dashboard, setDashboard] = useState({});
    function numberConversion(val) {
        if (val >= 10000000) {
            val = (val / 10000000).toFixed(2) + " Cr";
        } else if (val >= 100000) {
            val = (val / 100000).toFixed(2) + " Lac";
        }
        /*else if(val >= 1000) val = (val/1000).toFixed(2) + ' K';*/
        return val;
    }
    const getDashboard = () => {
        Services.getDashboard("GET", null, token)
            .then((Response) => {
                console.log(Response);
                setLoading(false);

                if (Response.Status === 1) {
                    setDashboard(Response.Dashboard);
                } else {
                    toast.error(`${Response.Message}`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    });
                }
            })
            .catch((err) => {
                alert("something went wrong please try later");
                console.log(err);
            });
    };
    useEffect(() => {
        getDashboard();
    }, []);
    function formatIndianNumber(number) {
        return number.toLocaleString("en-IN", {
            maximumFractionDigits: 0,
            style: "decimal",
        });
    }
    const submitHandler = (e) => {
        e.preventDefault();

        if (fromRef.current.value.length === 0) {
            alert("from Date is required");
            return;
        } else if (toRef.current.value.length === 0) {
            alert("to Date is required");
            return;
        }
        let fromDate = new Date(fromRef.current.value);
        let toDate = new Date(toRef.current.value);

        if (fromDate < toDate === false) {
            alert("to Date should be greater than from Date");
            return;
        }

        const DateFormatter = (date) => {
            return `${date.getFullYear()}-${String(
                date.getMonth() + 1
            ).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`;
        };
        console.log(DateFormatter(fromDate));
        setLoading(true);
        Services.getDashboardFilter(
            "GET",
            null,
            token,
            DateFormatter(fromDate),
            DateFormatter(toDate)
        )
            .then((Response) => {
                console.log(Response);
                setLoading(false);

                if (Response.Status === 1) {
                    setDashboard(Response.Dashboard);
                } else {
                    toast.error(`${Response.Message}`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    });
                }
            })
            .catch((err) => {
                alert("something went wrong please try later");
                console.log(err);
            });
    };
    return (
        <Layout isactive={"dashboard"}>
            <div className={DashboardClasses["DashBoardWrapper"]}>
                <div className={DashboardClasses["dashboard-header-wrapper"]}>
                    <h3>Dashboard</h3>
                    <form className={DashboardClasses["filter"]}>
                        {" "}
                        <div className={DashboardClasses["form-control"]}>
                            <label htmlFor="fromDate">From Date</label>
                            <input
                                type={"Date"}
                                ref={fromRef}
                                max={moment().format("YYYY-MM-DD")}
                            />
                        </div>
                        <div className={DashboardClasses["form-control"]}>
                            <label htmlFor="toDate">To Date</label>
                            <input
                                type={"Date"}
                                ref={toRef}
                                max={moment().format("YYYY-MM-DD")}
                            />
                        </div>
                        <div>
                            <button type="submit" onClick={submitHandler}>
                                Submit
                            </button>
                            <button
                                style={{ background: "red" }}
                                type="submit"
                                onClick={(e) => {
                                    setLoading(true);
                                    e.preventDefault();
                                    getDashboard();
                                    fromRef.current.value = "";
                                    toRef.current.value = "";
                                }}>
                                Clear
                            </button>
                        </div>
                    </form>
                </div>
                {loading ? (
                    <Loader />
                ) : (
                    <div className={DashboardClasses["dashboard-items"]}>
                        <div className={DashboardClasses["dashboard-item"]}>
                            <h3>No Of Payouts</h3>
                            <p className={DashboardClasses["count"]}>
                                {formatIndianNumber(dashboard?.NoOfPayouts)}
                            </p>
                        </div>
                        <div className={DashboardClasses["dashboard-item"]}>
                            <h3>Total Payouts</h3>
                            <p className={DashboardClasses["count"]}>
                                {formatIndianNumber(
                                    dashboard?.TotalPayoutAmount
                                )}
                            </p>
                        </div>
                        <div className={DashboardClasses["dashboard-item"]}>
                            <h3>Total Wallet charge</h3>
                            <p className={DashboardClasses["count"]}>
                                {formatIndianNumber(
                                    dashboard?.TotalWalletCharges
                                )}
                            </p>
                        </div>
                        <div className={DashboardClasses["dashboard-item"]}>
                            <h3>Total wallet load</h3>
                            <p className={DashboardClasses["count"]}>
                                {formatIndianNumber(
                                    dashboard?.TotalWalletLoadAmount
                                )}
                            </p>
                        </div>
                        <div className={DashboardClasses["dashboard-item"]}>
                            <h3>Total User wallet Amount</h3>
                            <p className={DashboardClasses["count"]}>
                                {formatIndianNumber(
                                    dashboard?.TotalUsersWalletAmount
                                )}
                            </p>
                        </div>
                        <div className={DashboardClasses["dashboard-item"]}>
                            <h3>Total Referral Commissions Amount</h3>
                            <p className={DashboardClasses["count"]}>
                                {formatIndianNumber(
                                    dashboard?.TotalReferralCommissionsAmount
                                )}
                            </p>
                        </div>
                        <div className={DashboardClasses["dashboard-item"]}>
                            <h3>Total Instant Payout Amount</h3>
                            <p className={DashboardClasses["count"]}>
                                {formatIndianNumber(
                                    dashboard?.TotalInstantPayoutsAmount
                                )}
                            </p>
                        </div>
                        <div className={DashboardClasses["dashboard-item"]}>
                            <h3>Total Tone Payout Amount</h3>
                            <p className={DashboardClasses["count"]}>
                                {formatIndianNumber(
                                    dashboard?.TotalTOnePayountsAmount
                                )}
                            </p>
                        </div>
                    </div>
                )}
            </div>
        </Layout>
    );
}

export default Dashboard;
