import React, { useState } from "react";
import tableClasses from "Styles/mainCss.module.css";
import { IoClose } from "react-icons/io5";
import { useEffect } from "react";
import { Services } from "Services";
import { toast } from "react-toastify";
import Loader from "Components/utilities/loader";
function AddCategory(props) {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({});
    const CancelHandler = (e) => {
        e.stopPropagation();
        props.setmodal(false);
    };
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    useEffect(() => {
        let body = {
            accountNumber: props.data.KYCHolderAccountNo,
            IFSCCode: props.data.KYCHolderAccountIFSC,
        };
        Services.verifyBankDetails(
            "POST",
            JSON.stringify(body),
            token,
            props.id
        )
            .then((Response) => {
                setLoading(false);
                if (Response.Status === 1) {
                    setData(Response.AccountDetails);
                } else {
                    props.setmodal(false);
                    toast.error(`${Response.Message}`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    });
                }
            })
            .catch((err) => {
                props.setmodal(false);
                alert("something went wrong please try later");
                console.log(err);
            });
    }, []);

    const openhandler = (e) => {
        e.stopPropagation();
    };

    return (
        <div className={tableClasses.modal} onClick={CancelHandler}>
            <div
                className={`${tableClasses["modal-content"]} ${tableClasses["modal-transaction-details"]} `}
                onClick={openhandler}>
                <div className={tableClasses["modal-content-header"]}>
                    <div>
                        <h3>Account Details</h3>
                    </div>
                    <button
                        className={tableClasses["close-btn"]}
                        onClick={CancelHandler}>
                        <IoClose size={22} color={"red"} />
                    </button>
                </div>
                {loading ? (
                    <Loader />
                ) : (
                    <div className={tableClasses["add-items"]}>
                        <div
                            className={`${tableClasses["transaciton-detail-items"]} ${tableClasses["whitebg"]}`}>
                            <h3>Name</h3>
                            <p>:</p>
                            <p
                                style={{
                                    maxWidth: "15rem",
                                    wordWrap: "break-word",
                                }}>
                                {data?.nameAtBank}
                            </p>
                        </div>
                        <div
                            className={`${tableClasses["transaciton-detail-items"]} ${tableClasses["greyBg"]}`}>
                            <h3>Bank Name</h3>
                            <p>:</p>
                            <p>{data?.bankName}</p>
                        </div>
                        <div
                            className={`${tableClasses["transaciton-detail-items"]} ${tableClasses["whitebg"]}`}>
                            <h3>City</h3>
                            <p>:</p>
                            <p>{data?.city}</p>
                        </div>
                        <div
                            className={`${tableClasses["transaciton-detail-items"]} ${tableClasses["greyBg"]}`}>
                            <h3>Branch</h3>
                            <p>:</p>
                            <p>{data?.branch}</p>
                        </div>
                        <div
                            className={`${tableClasses["transaciton-detail-items"]} ${tableClasses["whitebg"]}`}>
                            <h3>Account Status</h3>
                            <p>:</p>
                            <p>{data?.accountStatus}</p>
                        </div>
                        {/* <div
                        className={`${tableClasses["transaciton-detail-items"]} ${tableClasses["greyBg"]}`}>
                        <h3>transaction Date</h3>
                        <p>:</p>
                        <p>
                            <Moment
                                format="MMMM Do YYYY hh:mm a"
                                subtract={{ hours: 5, minutes: 30 }}>
                                {
                                    new Date(
                                        transactionDetails?.transactionDateTime
                                    )
                                }
                            </Moment>
                        </p>
                    </div>
                   */}
                    </div>
                )}
            </div>
        </div>
    );
}

export default AddCategory;
