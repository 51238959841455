import React from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

function modal(props) {
    return (
        <div>
            <Modal
                open={props.open}
                onClose={props.onCloseModal}
                center
                showCloseIcon={false}>
                <div
                    style={{
                        width: "100%",
                        height: "100%",
                        maxWidth: "1000px",
                        maxHeight: "1000px",
                    }}>
                    <img
                        src={props?.img}
                        width={"100%"}
                        height={"100%"}
                        style={{ userSelect: "none" }}
                    />
                </div>
            </Modal>
        </div>
    );
}

export default modal;
