import React, { useEffect, useState, useRef } from "react";
import Layout from "Components/utilities/Layout";
import tableClasses from "Styles/mainCss.module.css";
import Table from "Components/utilities/table";
import Loader from "Components/utilities/loader";
import { Services } from "Services";
import { toast } from "react-toastify";
import Moment from "react-moment";
import { BiSearchAlt2 } from "react-icons/bi";

import { useNavigate } from "react-router-dom";

import { css } from "@emotion/react";
function User() {
    const Navigate = useNavigate();
    const searchref = useRef();
    const searchoptionref = useRef();
    const [loading, setLoading] = useState(true);

    const [downloadload, setdownloadload] = useState(false);
    const [data, setData] = useState([]);
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    let firstTime;
    const [prevoffset, setPrevOffset] = useState();
    const [next, setNext] = useState(true);
    const [prev, setprev] = useState(true);
    const [offset, setoffset] = useState(0);
    const [max, setMax] = useState();
    const headerstyles = {
        color: "#1a1717",
        backgroundColor: "white",
        fontWeight: 600,
        fontSize: "0.9rem",
        width: "fit-content",
        textAlign: "center",
    };
    useEffect(() => {
        // eslint-disable-next-line
        firstTime = true;
    }, []);

    const getusers = (offset) => {
        Services.ApprovalUsers("GET", null, token, offset)
            .then((Response) => {
                console.log(Response);
                // offset

                setLoading(false);
                if (Response.Status === 1) {
                    setData(Response.users);
                    if (firstTime) {
                        firstTime = false;
                        setMax(Response.offset);
                    }

                    if (Response.offset !== -1) {
                        setoffset(Response.offset);
                        setNext(false);
                    } else {
                        setNext(true);
                    }
                } else {
                    toast.error(`${Response.Message}`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    });
                }
            })
            .catch((err) => {
                alert("something went wrong please try later");
                console.log(err);
            });
    };
    useEffect(() => {
        getusers(0);
        // eslint-disable-next-line
    }, []);
    const row = {
        onClick: (e, row, rowIndex) => {
            console.log(row);
            Navigate(`/dashboard/verified-users/${row.userId}?type=approval`);
        },
    };
    const dateFormatter = (cell) => {
        const date = new Date(cell);
        return (
            <Moment
                format="MMMM Do YYYY hh:mm a"
                subtract={{ hours: 5, minutes: 30 }}>
                {date}
            </Moment>
        );
    };
    const columns = [
        {
            dataField: "firstName",
            text: "Name",
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },
        {
            dataField: "emailId",
            text: "Email",
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles, width: "20%" };
            },
        },
        {
            dataField: "mobileNumber",
            text: "Mobile Number",
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },
        {
            dataField: "registrationDate",
            text: "Registration date",
            sort: false,
            formatter: dateFormatter,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles, width: "20%" };
            },
        },
        {
            dataField: "referredByCode",
            text: "Referral Code",
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },
        {
            dataField: "walletCommissionPercentage",
            text: "Wallet Commission Percentage",
            sort: false,
            formatter: (cell) => (
                <p style={{ textAlign: "center" }}>{cell}% </p>
            ),
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },

        {
            dataField: "walletAmount",
            text: "Wallet Balance",
            sort: false,
            formatter: (cell) => (
                <p style={{ textAlign: "center", margin: "0" }}>₹{cell} </p>
            ),
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },
    ];
    const submithandler = (e) => {
        // setLoading(true);
        e.preventDefault();
        if (
            searchref.current.value.length == 0 &&
            searchoptionref.current.value != "5"
        ) {
            getusers(0);
            return;
        }
        console.log("sss");
        let body = {};
        if (searchoptionref.current.value === "2") {
            body = { name: searchref.current.value, isKYCverified: 1 };
        } else if (searchoptionref.current.value === "3") {
            body = { mobileNumber: searchref.current.value, isKYCverified: 1 };
        } else if (searchoptionref.current.value === "4") {
            body = { referralCode: searchref.current.value, isKYCverified: 1 };
        } else if (searchoptionref.current.value === "5") {
            body = { pending: "", isKYCverified: 1 };
        } else if (searchoptionref.current.value === "1") {
            alert("please select search parameter");
            return;
        }

        Services.SearchUserBynameOrMobile("POST", JSON.stringify(body), token)
            .then((Response) => {
                console.log(Response);
                setLoading(false);
                if (Response.Status === 1) {
                    setData(Response.Users);
                    setNext(true);
                } else {
                    toast.error(`${Response.Message}`, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    });
                }
            })
            .catch((err) => {
                alert("something went wrong please try later");
                console.log(err);
            });
    };
    const clearHandler = (e) => {
        e.preventDefault();
        getusers(0);
        searchref.current.value = "";
        searchoptionref.current.value = 1;
    };
    const download = (offset) => {
        setdownloadload(true);
        // return;
        Services.approvalcsv("GET", null, token, offset)
            .then((Response) => {
                console.log(Response);
                // offset
                setdownloadload(false);

                if (Response.Status === 1) {
                    window.open(Response.FileName, "_blank");
                } else {
                    toast.error(`${Response.Message}`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    });
                }
            })
            .catch((err) => {
                alert("something went wrong please try later");
                console.log(err);
            });
    };
    const exportHandler = (e) => {
        download(offset);
    };
    return (
        <Layout isactive={"approval"}>
            {loading ? (
                <Loader />
            ) : (
                <div className={tableClasses["table"]}>
                    <h3 className={tableClasses["heading"]}>Approval</h3>

                    <div className={tableClasses["verified-export"]}>
                        <div></div>
                        {/* <form
                            className={tableClasses["search-form"]}
                            onSubmit={submithandler}>
                            <select defaultValue={1} ref={searchoptionref}>
                                <option value={1}>Search By</option>
                                <option value={2}>Name</option>
                                <option value={3}>Mobile Number</option>
                                <option value={4}>Referral Id</option>
                                <option value={5}>Pending Verification</option>
                            </select>
                            <div>
                                <input
                                    type={"text"}
                                    ref={searchref}
                                    // placeholder=""
                                />
                                <BiSearchAlt2
                                    className={tableClasses["search-icon"]}
                                    size={25}
                                    color={"#2e3346"}
                                />
                            </div>
                            <button type="submit">Search</button>
                            <button
                                onClick={clearHandler}
                                style={{
                                    marginLeft: "0.1rem",
                                    background: "red",
                                }}
                                type="button">
                                Clear
                            </button>
                        </form> */}

                        <button
                            onClick={exportHandler}
                            disabled={downloadload}
                            style={{ margin: "0.5rem" }}>
                            {downloadload ? "loading..." : "export"}
                        </button>
                    </div>
                    <Table
                        data={data}
                        columns={columns}
                        getdata={getusers}
                        getoffset={offset}
                        max={max}
                        setNext={setNext}
                        next={next}
                        prev={prev}
                        setprev={setprev}
                        prevoffset={prevoffset}
                        setPrevOffset={setPrevOffset}
                        rowevents={row}
                    />
                </div>
            )}
        </Layout>
    );
}

export default User;
