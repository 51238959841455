import React, { useEffect, useState } from "react";
import mainClasses from "Styles/mainCss.module.css";
import { IoClose } from "react-icons/io5";
import { useForm } from "react-hook-form";
import { Services } from "Services";
import { toast } from "react-toastify";
import tableClasses from "Styles/mainCss.module.css";

function EditUser(props) {
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const [payoutenable, setpayoutEnable] = useState(props.isPayBillsEnabled);
    const [isbbpsenable, setisbbpsenabled] = useState(props.isBBPSvisible);
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        reset({
            isBBPSvisible: props?.isBBPSvisible,
            isPayBillsEnabled: props?.isPayBillsEnabled,
            walletCommissionPercentage: props.walletCommissionPercentage,
            payoutChargeAmount: props.payoutChargeAmount,
            referredByCode: props.referredByCode,
            referredbyUserPhoneNumber: props.referredbyUserPhoneNumber,
            referralCommissionPercentage: props.referralCommissionPercentage,
            monthlyLimit: props.monthlyLimit,
            payoutChargePercentage_TOne: props.data.payoutChargePercentage_TOne,
            payoutChargePercentage: props.data.payoutChargePercentage,
        });
        // eslint-disable-next-line
    }, []);
    const CancelHandler = (e) => {
        e.stopPropagation();
        props.setmodal(false);
    };

    const openhandler = (e) => {
        e.stopPropagation();
    };

    const onSubmit = (data) => {
        let method = JSON.stringify({
            ...data,
            isPayBillsEnabled: payoutenable,
            isBBPSvisible: isbbpsenable,
        });
        Services.kycUpdate("PUT", method, token, props.id)
            .then((res) => {
                console.log(res);

                if (res.Status === 1) {
                    props.setmodal(false);
                    toast.success(`${res.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    props.getusers();
                } else if (res.Status === 0) {
                    toast.error(`${res.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
            .catch((err) => {
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    };

    return (
        <div className={mainClasses.modal} onClick={CancelHandler}>
            <div className={mainClasses["modal-content"]} onClick={openhandler}>
                <div className={mainClasses["modal-content-header"]}>
                    <div>
                        <p>Update User and necessary information from here</p>
                    </div>
                    <button
                        className={mainClasses["close-btn"]}
                        onClick={CancelHandler}>
                        <IoClose size={22} color={"red"} />
                    </button>
                </div>
                <form
                    className={mainClasses["add-items"]}
                    onSubmit={handleSubmit(onSubmit)}>
                    <div className={mainClasses["item-image-upload"]}>
                        <label htmlFor="Referred by code">
                            Referred by code
                        </label>
                        <div className={mainClasses["select"]}>
                            <input
                                type="text"
                                {...register("referredByCode", {
                                    required:
                                        "referredByCode by code is Required!",
                                })}
                            />
                            {errors?.referredByCode && (
                                <p className={mainClasses["error"]}>
                                    {errors?.referredByCode?.message}
                                </p>
                            )}
                        </div>
                    </div>

                    <div className={mainClasses["item-image-upload"]}>
                        <label htmlFor="payout charge amount">
                            Payout charge amount
                        </label>
                        <div className={mainClasses["select"]}>
                            <input
                                type="number"
                                {...register("payoutChargeAmount", {
                                    required:
                                        "payout charge amount is Required!",
                                })}
                            />
                            {errors?.payoutChargeAmount && (
                                <p className={mainClasses["error"]}>
                                    {errors?.payoutChargeAmount?.message}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className={mainClasses["item-image-upload"]}>
                        <label htmlFor="wallet commission percentage">
                            Wallet commission Percentage
                        </label>
                        <div className={mainClasses["select"]}>
                            <input
                                step={0.01}
                                type="number"
                                {...register("walletCommissionPercentage", {
                                    required:
                                        "wallet commission percentage is Required!",
                                })}
                            />
                            {errors?.walletCommissionPercentage && (
                                <p className={mainClasses["error"]}>
                                    {
                                        errors?.walletCommissionPercentage
                                            ?.message
                                    }
                                </p>
                            )}
                        </div>
                    </div>
                    <div className={mainClasses["item-image-upload"]}>
                        <label htmlFor="referredbyUserPhoneNumber">
                            referred by Mobile Number
                        </label>
                        <div className={mainClasses["select"]}>
                            <input
                                step={0.01}
                                type="number"
                                {...register("referredbyUserPhoneNumber")}
                            />
                            {errors?.referredbyUserPhoneNumber && (
                                <p className={mainClasses["error"]}>
                                    {errors?.referredbyUserPhoneNumber?.message}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className={mainClasses["item-image-upload"]}>
                        <label htmlFor="referralCommissionPercentage">
                            referral Commission Percentage
                        </label>
                        <div className={mainClasses["select"]}>
                            <input
                                step={0.01}
                                type="number"
                                {...register("referralCommissionPercentage")}
                            />
                            {errors?.referralCommissionPercentage && (
                                <p className={mainClasses["error"]}>
                                    {
                                        errors?.referralCommissionPercentage
                                            ?.message
                                    }
                                </p>
                            )}
                        </div>
                    </div>
                    <div className={mainClasses["item-image-upload"]}>
                        <label htmlFor="referralCommissionPercentage">
                            payout Charge Percentage
                        </label>
                        <div className={mainClasses["select"]}>
                            <input
                                step={0.01}
                                type="number"
                                {...register("payoutChargePercentage")}
                            />
                            {errors?.payoutChargePercentage && (
                                <p className={mainClasses["error"]}>
                                    {errors?.payoutChargePercentage?.message}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className={mainClasses["item-image-upload"]}>
                        <label htmlFor="referralCommissionPercentage">
                            payout Charge Percentage_TOne
                        </label>
                        <div className={mainClasses["select"]}>
                            <input
                                step={0.01}
                                type="number"
                                {...register("payoutChargePercentage_TOne")}
                            />
                            {errors?.payoutChargePercentage_TOne && (
                                <p className={mainClasses["error"]}>
                                    {
                                        errors?.payoutChargePercentage_TOne
                                            ?.message
                                    }
                                </p>
                            )}
                        </div>
                    </div>

                    <div className={mainClasses["item-image-upload"]}>
                        <label htmlFor="referralCommissionPercentage">
                            monthly Limit
                        </label>
                        <div className={mainClasses["select"]}>
                            <input
                                step={0.01}
                                type="number"
                                {...register("monthlyLimit", {
                                    min: 500000,
                                    max: 500000000,
                                })}
                            />
                            {errors?.monthlyLimit && (
                                <>
                                    {errors.monthlyLimit?.type === "min" && (
                                        <p>Minimum amount is 500000.</p>
                                    )}
                                    {errors.monthlyLimit?.type === "max" && (
                                        <p>Maximum amount is 50000000.</p>
                                    )}
                                </>
                            )}
                        </div>
                    </div>

                    <div
                        className={tableClasses["settting-detail"]}
                        style={{ display: "flex", alignItems: "center" }}>
                        <label htmlFor="isbbpsenable">BBPS Enable</label>
                        <div className={tableClasses["radio-button-wrapper"]}>
                            <div className={tableClasses["radio-buttons"]}>
                                <input
                                    type={"radio"}
                                    name="isbbpsenable"
                                    value={1}
                                    onChange={() => setisbbpsenabled(1)}
                                    defaultChecked={isbbpsenable === 1}
                                />
                                <label htmlFor="yes">enable</label>
                            </div>
                            <div className={tableClasses["radio-buttons"]}>
                                <input
                                    type={"radio"}
                                    onChange={() => setisbbpsenabled(0)}
                                    name="isbbpsenable"
                                    value={0}
                                    defaultChecked={isbbpsenable == 0}
                                />
                                <label htmlFor="No">disable</label>
                            </div>
                        </div>
                    </div>

                    <div
                        className={tableClasses["settting-detail"]}
                        style={{ display: "flex", alignItems: "center" }}>
                        <label htmlFor="rechargeLock">Payout Enable</label>
                        <div className={tableClasses["radio-button-wrapper"]}>
                            <div className={tableClasses["radio-buttons"]}>
                                <input
                                    type={"radio"}
                                    name="rechargeLock"
                                    value={1}
                                    onChange={() => setpayoutEnable(1)}
                                    defaultChecked={payoutenable == 1}
                                />
                                <label htmlFor="yes">enable</label>
                            </div>
                            <div className={tableClasses["radio-buttons"]}>
                                <input
                                    type={"radio"}
                                    onChange={() => setpayoutEnable(0)}
                                    name="rechargeLock"
                                    value={0}
                                    defaultChecked={payoutenable == 0}
                                />
                                <label htmlFor="No">disable</label>
                            </div>
                        </div>
                    </div>
                    <div className={mainClasses["add-btn"]}>
                        <button type="button" onClick={CancelHandler}>
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className={mainClasses["add-category-btn"]}>
                            update
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default EditUser;
